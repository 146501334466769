<template>
  <div class="group">
    <input
      :type="type"
      class="text_small"
      @input="onTextInput"
      v-model="this.value"
    />
    <span class="highlight"></span>
    <span class="bar"></span>
    <label
      v-bind:class="{ textFilled: isFilled, error: error }"
      class="text_small"
    >
      <slot></slot>
    </label>
    <p class="error-message">
      <span v-if="error">{{ this.errorMessage }}</span>
    </p>
  </div>
</template>

<script>
import * as _ from "lodash";
export default {
  props: {
    type: { type: String, required: false, defaultValue: "text" },
    name: { type: String, required: true },
    error: { type: Boolean, required: false, defaultValue: false },
    errorMessage: { type: String, required: false },
  },
  name: "uiTextField",
  data() {
    return {
      value: undefined,
      isFilled: false,
    };
  },
  methods: {
    onTextInput() {
      if (!_.isNil(this.value) && this.value !== "") {
        this.isFilled = true;
      } else {
        this.isFilled = false;
      }
      this.$emit("textChanged", this.name, this.value);
    },
    clean() {
      this.value = "";
      this.$emit("textChanged", this.name, this.value);
      this.isFilled = false;
    },
  },
};
</script>

<style scoped lang="scss">
.group {
  position: relative;
}

input {
  background: none;
  color: white;
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #c6c6c6;
}
input:focus {
  outline: none;
}
input:focus ~ label,
textarea:focus ~ label {
  top: -14px;
  font-size: 15px;
  color: #ed8202;
}
.textFilled {
  top: -14px;
  font-size: 15px;
  color: #ffffff;
}
input:focus ~ .bar:before {
  width: 100%;
}
input[type="password"] {
  letter-spacing: 0.3em;
}
label {
  color: white;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 300ms ease all;
}
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #ed8202;
  transition: 300ms ease all;
  left: 0%;
}
.error {
  color: #ff0b0c;
}
.error-message {
  color: #ff0b0c;
  font-size: 15px;
  height: 16px;
}
</style>
