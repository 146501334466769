<template>
  <section
    class="top-page-container"
    :style="{ 'background-image': 'url(' + src + ')' }"
  >
    <div class="top-page-container-overlay">
      <div class="top-page-container-content">
        <slot></slot>
      </div>
    </div>
    <div class="top-page-arrow desktop_show">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </section>
</template>

<script>
export default {
  name: "uiTopPageSection",
  props: {
    src: {
      type: String,
      required: true,
    },
    bgPosition: {
      type: Object,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../vars";
.top-page-container {
  background: no-repeat fixed;
  background-position-x: v-bind("bgPosition.x");
  background-position-y: 0;
  background-size: cover;
  height: 700px;
  position: relative;
  overflow: hidden;
  .top-page-container-overlay {
    background: linear-gradient(0deg, #0e0e0e 0%, rgba(20, 20, 20, 0) 100%);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    height: 100%;
    .top-page-container-content {
      color: white;
      margin: 350px 20px 0 20px;
    }
  }
}
.top-page-arrow {
  position: absolute;
  top: 88vh;
  left: 50%;
  transform: translate(-50%, -50%);
  span {
    display: block;
    width: 30px;
    height: 30px;
    border-bottom: 5px solid #ffbe4f;
    border-right: 5px solid #ffbe4f;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
    &:nth-child(2) {
      animation-delay: -0.2s;
    }
    &:nth-child(3) {
      animation-delay: -0.4s;
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
@supports (-webkit-touch-callout: none) {
  .top-page-container {
    background-attachment: unset;
  }
}
@media (min-width: $large_w) {
  .top-page-container {
    height: 952px;
    .top-page-container-overlay {
      .top-page-container-content {
        text-align: center;
        max-width: 1600px;
        margin: auto;
      }
    }
  }
}
</style>
