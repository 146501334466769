<template>
  <div class="custom-select-component" @blur="open = false">
    <div
      class="label"
      :class="{ error: error }"
      v-if="this.default !== this.current"
    >
      {{ this.label }}
    </div>
    <div
      class="selected text_small"
      :class="{ open: open, error: error }"
      @click.stop.prevent="toggleSelect"
    >
      {{ current }}
    </div>
    <p class="error-message">
      <span v-if="error">{{ this.errorMessage }}</span>
    </p>
    <div class="items" :class="{ selectHide: !open }">
      <div
        class="text_small"
        v-for="(item, i) of options"
        :key="i"
        @click="change(item)"
      >
        {{ item }}
      </div>
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
export default {
  props: {
    options: { type: Array, required: true },
    default: { type: String, required: false },
    label: { type: String, required: true },
    name: { type: String, required: true },
    error: { type: Boolean, required: false, defaultValue: false },
    errorMessage: { type: String, required: false },
  },
  data() {
    return {
      current: "",
      available: [],
      open: false,
    };
  },
  mounted() {
    if (!_.isNil(this.default)) {
      this.current = this.default;
    }
  },
  methods: {
    change(item) {
      this.current = item;
      this.toggleSelect();
      this.$emit("select", this.name, this.current);
    },
    toggleSelect() {
      if (this.open) {
        window.removeEventListener("click", this.docClick);
        this.open = false;
      } else {
        window.addEventListener("click", this.docClick);
        this.open = true;
      }
    },
    docClick() {
      this.toggleSelect();
    },
    clean() {
      this.current = this.default;
      this.$emit("select", this.name, this.current);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../vars";
.custom-select-component {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 55px;
  line-height: 55px;
  .selected {
    border-radius: 5px;
    border: 1px solid #9c9c9c;
    color: #fff;
    padding-left: 1em;
    cursor: pointer;
    user-select: none;
    &.open {
      border: 1px solid #ad8225;
      border-radius: 5px 5px 0px 0px;
    }
    &:after {
      position: absolute;
      content: "";
      top: 25px;
      right: 1em;
      width: 0;
      height: 0;
      border: 7px solid transparent;
      border-color: #fff transparent transparent transparent;
    }
  }
  .items {
    color: #fff;
    border-radius: 0px 0px 5px 5px;
    overflow: hidden;
    border-right: 1px solid #ad8225;
    border-left: 1px solid #ad8225;
    border-bottom: 1px solid #ad8225;
    position: absolute;
    background-color: rgba(25, 25, 25, 0.9);
    left: 0;
    right: 0;
    z-index: 1;
    div {
      color: #fff;
      padding-left: 1em;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #ad8225;
      }
    }
  }
  .label {
    font-size: 15px;
    position: absolute;
    top: -40px;
    left: 5px;
    color: #ed8202;
  }
}
.selectHide {
  display: none;
}
.error {
  color: #ff0b0c !important;
}
.error-message {
  position: absolute;
  top: 45px;
  color: #ff0b0c;
  font-size: 15px;
  height: 16px;
}
</style>
