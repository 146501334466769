<template>
  <button v-ripple class="r-btn" :class="{ flat: flat }">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "uiButton",
  props: {
    flat: {
      type: Boolean,
      required: false,
      default: true,
    },
    bgColor: {
      type: String,
      required: false,
      default: "#000000",
    },
    borderRadius: {
      type: String,
      required: false,
      default: "0",
    },
    textColor: {
      type: String,
      required: false,
      default: "#000000",
    },
  },
};
</script>

<style scoped lang="scss">
.flat {
  background-color: transparent;
  border: none;
}
button {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 10px;
  position: relative;
  overflow: hidden;
  padding: 15px 30px;
  background-color: v-bind(bgColor);
  border-radius: v-bind(borderRadius);
  border: none;
  color: v-bind(textColor);
  transition: 0.2s;
  /*&::before {*/
  /*  content: "";*/
  /*  height: 3px;*/
  /*  width: 0;*/
  /*  left: 0;*/
  /*  bottom: 0;*/
  /*  position: absolute;*/
  /*  background: white;*/
  /*  transition: 0.4s;*/
  /*}*/
  /*&:hover {*/
  /*  &::before {*/
  /*    width: 100%;*/
  /*  }*/
  /*}*/
  /*&:active {*/
  /*  background-color: rgba(255, 255, 255, 0.1);*/
  /*}*/
}
</style>
