<template>
  <div
    class="alert"
    :style="{ top: this.top }"
    v-bind:class="{ 'error-alert': isError, 'success-alert': !isError }"
  >
    <span>{{ message }}</span>
  </div>
</template>

<script>
export default {
  name: "uiAlert",
  data() {
    return {
      message: "",
      top: "-100px",
      isError: false,
      timeout: null,
    };
  },
  methods: {
    show(message, isError = false) {
      clearTimeout(this.timeout);
      this.isError = isError;
      this.message = message;
      this.top = 0;
      this.timeout = setTimeout(() => {
        this.top = "-100px";
      }, 3500);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../vars";
.alert {
  transition: all 0.5s;
  font-size: 15px;
  font-weight: bold;
  position: fixed;
  z-index: 10000000;
  padding: 25px;
  border-radius: 0 0 10px 10px;
  box-shadow: 1px 1px 10px black;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
}
.error-alert {
  background-color: #ff782e;
  color: black;
}
.success-alert {
  background-color: #3b3b3b;
  color: #f6ffb9;
}
@media (min-width: $medium_w) {
  .alert {
    width: auto;
  }
}
</style>
