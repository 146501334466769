<template>
  <div class="table-wrap">
    <table>
      <thead>
        <tr>
          <th
            class="text_small text_bold"
            v-for="head in tableHead"
            :key="head"
          >
            {{ $t(head) }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(dataRow, index) in tableData"
          :key="index"
          class="b_margin_medium"
        >
          <td v-for="(cell, cellIndex) in dataRow" :key="cell">
            <span class="table-mobile-header text_small">
              {{ $t(tableHead[cellIndex]) }}
            </span>
            <span class="table-cell" v-if="cell === '+' || cell === '-'">
              <font-awesome-icon
                class="table_icon"
                v-if="cell === '+'"
                icon="check"
              />
              <font-awesome-icon
                class="table_icon"
                v-if="cell === '-'"
                icon="times"
              />
            </span>
            <span
              v-else-if="$te(cell)"
              class="table-cell text_small"
            >
              {{ $t(cell.toString()) }}
            </span>
            <span
              v-else
              class="table-cell text_small"
            >
              {{cell}}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "uiTable",
  props: {
    tableHead: {
      type: Array,
      required: true,
    },
    tableData: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../vars";
.table-wrap {
  width: 100%;
}

table {
  border: 0;
  margin: auto;
  width: 100%;
  tbody {
    display: flex;
    flex-direction: column;
  }
  thead {
    display: none;
  }
  tr {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    background-color: white;
  }
  td {
    width: 100%;
    display: flex;
    flex-grow: 1;

    .table-mobile-header {
      display: flex;
      flex: 0 1 50%;
      font-weight: bold;
      background-color: #ffbe4f;
      padding: 15px 8px;
      align-items: center;
    }
    .table-cell {
      display: flex;
      flex: 0 1 50%;
      background-color: white;
      padding: 15px 8px;
      align-items: center;
    }
    .table-head-cell {
      display: flex;
      flex: 0 1 50%;
      background-color: #ffbe4f;
      padding: 15px 15px;
      align-items: center;
    }
  }
}
@media (min-width: $small_w) {
  table {
    td {
      .table-mobile-header {
        padding: 25px 0 25px 25px;
      }
      .table-cell {
        padding: 25px 0 25px 25px;
      }
    }
  }
}

@media (min-width: $large_w) {
  table {
    max-width: 1600px;
    tbody {
      flex-direction: column;
    }
    thead {
      display: flex;
      th {
        width: 100%;
        text-align: left;
        padding: 15px;
        background-color: #ffbe4f;
      }
    }
    tr {
      flex-direction: row;
      margin-bottom: 0;
    }
    td {
      display: initial;
      .table-mobile-header {
        display: none;
      }
      .table-cell {
        padding: 15px;
      }
    }
  }
}

@media (min-width: $extra_large_w) {
  table {
    thead {
      th {
        padding: 25px;
      }
    }
    td {
      .table-cell {
        padding: 25px;
      }
    }
  }
}
</style>
