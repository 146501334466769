<template>
  <div class="container">
    <uiAlert ref="alert"></uiAlert>
    <uiTopPageSection
      :src="backgroundImage"
      :bgPosition="{ x: '60%', y: '60%' }"
    >
      <div class="top-content-section">
        <h1 class="text_uppercase b_margin_small text_super_big">
          {{ $t("order.topPage.p1") }}
        </h1>
        <p class="text_small">{{ $t("order.topPage.p2") }}</p>
      </div>
    </uiTopPageSection>
    <section class="order-options section light">
      <div class="padding_medium">
        <h2 class="text_medium text_center text_bold">
          {{ $t("order.section1.p1") }}
        </h2>
        <p class="text_center text_small">{{ $t("order.section1.p2") }}</p>
      </div>
      <ui-table :table-head="tableHead" :table-data="tableData" />
      <p class="text_small text_right text_italic t_margin_small">
        {{ $t("order.section1.p3") }}
      </p>
      <p class="text_small text_right text_italic">
        {{ $t("order.section1.p4") }}
      </p>
      <p class="text_small text_right v_padding_medium">
        {{ $t("order.section1.p5") }}
      </p>
    </section>
    <section class="order-form section">
      <div class="order-form-wrap-bg"></div>
      <div class="order-form-items">
        <div class="order-form__header order-form-item">
          <h2 class="text_small text_bold b_margin_small">
            {{ $t("order.section2.p1") }}
          </h2>
          <p class="text_small te b_margin_medium">
            {{ $t("order.section2.p2") }}
          </p>
        </div>
        <form class="order-form__form order-form-item">
          <ui-text-field
            class="v_margin_big"
            name="firstName"
            ref="formField1"
            :error-message="this.$i18n.t('messages.error1')"
            :error="this.validationErrors.firstName"
            @textChanged="textInput"
          >
            {{ $t("order.section2.i1") }}
          </ui-text-field>
          <ui-text-field
            class="v_margin_big"
            name="lastName"
            ref="formField2"
            @textChanged="textInput"
            :error-message="this.$i18n.t('messages.error1')"
            :error="this.validationErrors.lastName"
            >{{ $t("order.section2.i2") }}
          </ui-text-field>
          <ui-text-field
            class="v_margin_big"
            name="country"
            ref="formField3"
            @textChanged="textInput"
            >{{ $t("order.section2.i3") }}
          </ui-text-field>
          <ui-text-field
            class="v_margin_big"
            name="phone"
            ref="formField4"
            @textChanged="textInput"
            >{{ $t("order.section2.i4") }}
          </ui-text-field>
          <ui-text-field
            class="v_margin_big"
            name="mail"
            ref="formField5"
            @textChanged="textInput"
            :error-message="this.$i18n.t('messages.error1')"
            :error="this.validationErrors.mail"
            >{{ $t("order.section2.i5") }}
          </ui-text-field>
          <ui-select-component
            :options="orderSelect"
            :default="this.$i18n.t('order.section2.i6')"
            :label="this.$i18n.t('order.section2.i7')"
            class="v_margin_big b_margin_big"
            ref="formField6"
            @select="selectInput"
            name="package"
            :error-message="this.$i18n.t('messages.error1')"
            :error="this.validationErrors.package"
          />
          <ui-button
            class="button text_small"
            :flat="false"
            bg-color="#FFBE4F"
            border-radius="5px"
            @click.prevent="sendForm"
          >
            {{ $t("order.section2.b1") }}
          </ui-button>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import backgroundImage from "@/assets/orderNow/order_bg_image.jpg";
import uiTable from "../components/UI/uiTable";
import uiButton from "../components/UI/uiButton";
import uiTextField from "../components/UI/uiTextField";
import uiTopPageSection from "../components/UI/uiTopPageSection";
import UiSelectComponent from "../components/UI/uiSelectComponent";
import * as _ from "lodash";
import uiAlert from "../components/UI/uiAlert";

export default {
  name: "Order",
  components: {
    UiSelectComponent,
    uiTable,
    uiTextField,
    uiButton,
    uiTopPageSection,
    uiAlert,
  },
  data() {
    return {
      backgroundImage,
      tableHead: [
        "order.section1.tableHead[0]",
        "order.section1.tableHead[1]",
        "order.section1.tableHead[2]",
        "order.section1.tableHead[3]",
        "order.section1.tableHead[4]",
        "order.section1.tableHead[5]",
        "order.section1.tableHead[6]",
      ],
      tableData: [
        ["Platinum", "+", "+", 6, "+", "1+7", "order.section1.price_by_request"],
        ["Gold", "+", "+", 6, "+", "-", "order.section1.price_by_request"],
        ["Silver", "+", "+", 4, "-", "-", "order.section1.price_by_request"],
      ],
      orderSelect: [
        this.$i18n.t("order.section2.packages[0]"),
        this.$i18n.t("order.section2.packages[1]"),
        this.$i18n.t("order.section2.packages[2]"),
      ],
      form: {
        type: "order",
        firstName: "",
        lastName: "",
        country: "",
        phone: "",
        mail: "",
        package: "",
      },
      validation: {
        notEmpty: ["firstName", "lastName", "mail", "package"],
      },
      validationErrors: {},
    };
  },
  methods: {
    textInput(name, value) {
      if (_.has(this.form, name)) {
        this.form[name] = value;
      }
      if (_.has(this.validationErrors, name)) {
        this.validationErrors[name] = false;
      }
    },
    selectInput(name, value) {
      if (_.has(this.form, name)) {
        this.form[name] = value;
      }
      if (_.has(this.validationErrors, name)) {
        this.validationErrors[name] = false;
      }
    },
    validateForm() {
      this.validationErrors = {};
      let totalErrors = 0;
      _.forEach(this.validation.notEmpty, (fieldName) => {
        if (_.isNil(this.form[fieldName]) || this.form[fieldName] == "") {
          this.validationErrors[fieldName] = true;
          totalErrors++;
        } else {
          this.validationErrors[fieldName] = false;
        }
      });
      if (totalErrors === 0) {
        return true;
      } else {
        return false;
      }
    },
    sendForm() {
      let validate = this.validateForm();
      if (validate) {
        this.$store.dispatch("sendMail", this.form);
        this.$refs.alert.show(this.$i18n.t("messages.sent"));
        this.clearForm();
      } else {
        this.$refs.alert.show(this.$i18n.t("messages.error2"), true);
      }
    },
    clearForm() {
      for (let i = 1; i <= 6; i++) {
        this.$refs[`formField${i}`].clean();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../vars";
.order-options {
}
.order-form {
  background-image: url("/armor/table_bg.jpg");
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  color: white;
  width: 100%;
  display: flex;
  flex-direction: column;
  .order-form-wrap-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .order-form-items {
    z-index: 5;
  }
  form {
    width: 100%;
    margin: auto;
    .button {
      width: 100%;
      margin: 0;
      font-weight: bold;
    }
  }
  .order-form__header {
  }
}
@supports (-webkit-touch-callout: none) {
  .order-form {
    background-attachment: unset;
  }
}
@media (min-width: $medium_w) {
  .order-form__header {
    width: 70%;
    margin: 0 auto;
  }
  .order-form form {
    width: 70%;
  }
}
@media (min-width: $large_w) {
  .order-form-items {
    display: flex;
    flex-direction: row;
    max-width: 1600px;
    margin: auto;
    align-items: center;
    h2 {
      font-size: 40px;
    }
  }
}
</style>
